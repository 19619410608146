<template>
  <div class="d-flex flex-column chat-card overflow-hidden">
    <BackToChats v-if="chat" :text="theOther.complete_name"></BackToChats>
    <div v-if="chat" style="flex: 1 1 1px" class="overflow-y-auto d-flex flex-column-reverse px-2">
      <div
        v-for="(text, i) in pendingMessages.slice().reverse()"
        :key="`${i}-${text}`"
        class="flex-row-reverse d-flex my-2"
      >
        <message :message="{ text, user: { isMe: true } }" />
      </div>
      <template v-for="(msg, i) in chat?.messages.data ?? []">
        <div :key="'b' + i" :class="[{ 'flex-row-reverse': msg.user.isMe }, 'd-flex my-2 flex-wrap']">
          <message :message="msg" />
        </div>
        <div :key="'a' + i" class="d-flex justify-center date-ref my-2" v-if="dayChangesMapping[i]">
          <v-card dark>
            <v-card-text class="primary lighten-2 py-1 px-2"> {{ moment(msg.created_at).format("LL") }} </v-card-text>
          </v-card>
        </div>
      </template>

      <div v-if="chat.messages.paginatorInfo.hasMorePages" class="d-flex justify-center py-2">
        <v-btn :loading="$apollo.queries.chat.loading" color="primary" text @click="fetchMore">
          Carica altri messaggi
        </v-btn>
      </div>
    </div>

    <v-row v-else class="justify-center align-center">
      <v-progress-circular size="46" indeterminate color="primary" />
    </v-row>

    <chat-footer @sendMessage="sendMessage($event)"></chat-footer>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Message, { MESSAGE_FRAGMENT } from "./Message.vue";
import _debounce from "lodash/debounce";

import ChatFooter from "./ChatFooter.vue";
import BackToChats from "./BackToChats.vue";

import moment from "moment";

const CHAT_QUERY = gql`
  query Chat($id: ID!, $page: Int) {
    chat(id: $id) {
      id
      unreadMessagesByMeCount
      messages(first: 15, page: $page) {
        data {
          ...message
          created_at
        }
        paginatorInfo {
          total
          hasMorePages
        }
      }
      users {
        id
        isMe
        complete_name
      }
      latestMessage {
        ...message
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;

export default {
  components: { Message, ChatFooter, BackToChats },
  props: { chat_id: String },
  data() {
    return {
      pendingMessages: [],
      page: 1,
      moment,
    };
  },
  computed: {
    me() {
      return this.chat.users[0].isMe ? this.chat.users[0] : this.chat.users[1];
    },
    theOther() {
      return !this.chat.users[0].isMe ? this.chat.users[0] : this.chat.users[1];
    },
    dayChangesMapping() {
      return this.chat.messages.data.map((msg, i) => {
        if (i === this.chat.messages.data.length - 1) return true;

        const currentDay = moment(msg.created_at);
        const nextDay = moment(this.chat.messages.data[i + 1].created_at);

        return !currentDay.isSame(nextDay, "day");
      });
    },
    sendMessages() {
      return _debounce(() => {
        const input = this.pendingMessages.map((text) => {
          return {
            text,
            chat_id: this.chat_id,
          };
        });

        this.$apollo
          .mutate({
            mutation: gql`
              mutation SendMessages($input: [SendMessageInput!]!) {
                sendMessages(input: $input) {
                  ...message
                }
              }
              ${MESSAGE_FRAGMENT}
            `,
            variables: {
              input,
            },
            update: (store, { data }) => {
              store.writeFragment({
                id: `Chat:${this.chat_id}`,
                fragmentName: "chatMessagesTotal",
                fragment: gql`
                  fragment chatMessagesTotal on Chat {
                    id
                    messages(first: 15) {
                      data {
                        ...message
                      }
                      paginatorInfo {
                        total
                      }
                    }
                  }
                  ${MESSAGE_FRAGMENT}
                `,
                data: {
                  __typename: "Chat",
                  id: this.chat_id,
                  messages: {
                    __typename: "MessagesConnection",
                    data: [...data.sendMessages.slice().reverse(), ...this.chat.messages.data],
                    paginatorInfo: {
                      __typename: "PaginatorInfo",
                      total: this.chat.messages.paginatorInfo.total + data.sendMessages.length,
                    },
                  },
                },
              });

              this.pendingMessages = this.pendingMessages.slice(data.sendMessages.length);
            },
          })
          .catch((error) => {
            console.error(error);
          });
      }, 500);
    },
  },
  apollo: {
    $subscribe: {
      messagesRead: {
        query: gql`
          subscription MessagesRead($input: MessagesReadInput!) {
            messagesRead(input: $input) {
              id
              read_at
            }
          }
        `,
        variables() {
          return { input: { chat_id: this.chat_id } };
        },
      },
    },
    chat: {
      query: CHAT_QUERY,
      variables() {
        return { id: this.chat_id };
      },
      skip() {
        return !this.chat_id;
      },
      result({ loading }) {
        if (!loading && this.chat.unreadMessagesByMeCount) this.markMessagesAsRead();
      },
      fetchPolicy: "cache-and-network",
      subscribeToMore: {
        document: gql`
          subscription NewMessages($input: NewMessagesInput!) {
            newMessages(input: $input) {
              ...message
            }
          }
          ${MESSAGE_FRAGMENT}
        `,
        variables() {
          return { input: { chat_id: this.chat_id } };
        },
        // Mutate the previous result
        updateQuery(previousResult, { subscriptionData: { data } }) {
          this.markMessagesAsRead();
          const { newMessages } = data;
          const messages = newMessages.slice().reverse().concat(previousResult.chat.messages.data);

          return {
            ...previousResult,
            chat: {
              ...previousResult.chat,
              messages: {
                ...previousResult.chat.messages,
                paginatorInfo: { ...previousResult.chat.messages.paginatorInfo, total: messages.length },
                data: messages,
              },
              unreadMessagesByMeCount: 0,
              latestMessage: messages[messages.length - 1],
            },
          };
        },
        skip() {
          return !this.chat_id;
        },
      },
    },
  },
  methods: {
    sendMessage(text) {
      this.pendingMessages.push(text);
      this.sendMessages();
    },

    markMessagesAsRead() {
      this.$apollo.mutate({
        mutation: gql`
          mutation MarkMessagesAsRead($chat_id: ID!) {
            markMessagesAsRead(chat_id: $chat_id) {
              id
              unreadMessagesByMeCount
            }
          }
        `,
        variables: { chat_id: this.chat_id },
        optimisticResponse: {
          markMessagesAsRead: {
            __typename: "Chat",
            id: this.chat_id,
            unreadMessagesByMeCount: 0,
          },
        },
      });
    },
    fetchMore() {
      this.$apollo.queries.chat.fetchMore({
        variables: {
          id: this.chat_id,
          page: ++this.page,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;
          return {
            chat: {
              ...previousResult.chat,
              messages: {
                ...previousResult.chat.messages,
                data: [...previousResult.chat.messages.data, ...fetchMoreResult.chat.messages.data],
                paginatorInfo: {
                  ...previousResult.chat.messages.paginatorInfo,
                  hasMorePages: fetchMoreResult.chat.messages.paginatorInfo.hasMorePages,
                },
              },
            },
          };
        },
      });
    },
  },
};
</script>
